// Globals
html {
  scroll-behavior: smooth;
  -webkit-scroll-restoration: auto;
}

body {
  background-color: $body-background-color-light-grey;
  font-family: $font-primary;
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  margin: 0;
}

code {
  font-family: proxima-nova, source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

// Animations
@keyframes slideOut {
  0% {
    opacity: 1;
    margin-left: 20px;
  }
  10% {
    opacity: 0.95;
    margin-left: 30px;
  }
  20% {
    opacity: 0.9;
    margin-left: 40px;
  }
  30% {
    opacity: 0.85;
    margin-left: 50px;
  }
  45% {
    opacity: 0.75;
    margin-left: 60px;
  }
  55% {
    opacity: 0.6;
    margin-left: 70px;
  }
  65% {
    opacity: 0.4;
    margin-left: 80px;
  }
  80% {
    opacity: 0.2;
    margin-left: 90px;
  }
  100% {
    opacity: 0;
    margin-left: 120px;
  }
}

// Carousel
.custom-scrollbar-vertical {
  padding-right: 20px;
  touch-action: pan-y;

  /* Track */
  &::-webkit-scrollbar {
    width: 11px;
    height: 100%;
  }
  &::-webkit-scrollbar-track {
    box-shadow: 0 0 1px 3px #cdcdcd;
    background-color: #cdcdcd;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #1d1d1d;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #222;
  }
}

.carousel {
  overflow-y: hidden;
  overflow-x: auto;
  touch-action: pan-x;
  position: relative;
  min-width: 100%;
  width: 100%;

  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 20px;

  /* Track */
  &::-webkit-scrollbar {
    height: 11px;
    width: 100%;
  }
  &::-webkit-scrollbar-track {
    box-shadow: 0 0 1px 3px #cdcdcd;
    background-color: #cdcdcd;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #1d1d1d;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #222;
  }
}
.editedCarousel {
  align-items: flex-start;
  gap: 15px;
}

.custom-scroll {
  /* Track */
  &::-webkit-scrollbar {
    height: 11px;
    width: 100%;
  }
  &::-webkit-scrollbar-track {
    box-shadow: 0 0 1px 3px #cdcdcd;
    background-color: #cdcdcd;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #1d1d1d;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #222;
  }
}

.pointer-events-all {
  pointer-events: all;
}

// Text
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
