.swiper-pagination {
    width: 100%;
    display:flex;
    gap: 10px;
    justify-content: center;
    flex-direction: row;
    transform: translateY(100%);

    & .swiper-pagination-bullet {
       
    width: 10px;
    height: 10px;
    }

    & .swiper-pagination-bullet-active {
        width: 20px;
        background: transparent linear-gradient(180deg, #a8d060 0%, #8fbd3b 100%) 0% 0% no-repeat
        padding-box;
        transition: all 300ms;
        border-radius: 6px;
    }

}
.swiper-wrapper {
    justify-content: center;
}