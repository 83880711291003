.FreeContentCard {
  .PlayButton {
    transition: opacity ease-in-out 200ms;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
  }
  &:hover {
    .PlayButton {
      transition: opacity ease-in-out 200ms;
      opacity: 1;
    }
  }
}
